import React, { useContext, Fragment } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import { Helmet } from "react-helmet";
import "../Assets/Css/body.css";
import "../Assets/Css/heading.css";
import "../Assets/Css/styles.css";

import Image from 'react-bootstrap/Image';

import MainImage from "../Assets/Img/MainImage.jpeg";
import LogoSmall from "../Assets/Img/LogoSmall.png";

//import Header from "../Components/Header";
//import Footer from "../Components/Footer";
//import Offers from "../Components/Offers";
//import Facts from "../Components/Facts";
import Icofont from "react-icofont";

import Navigation from "../Components/Navigation";
import Copyrights from "../Components/Copyrights";
import AppContext from "../Components/AppContext";
//import OrderForm from "../Components/OrderForm";

import {
	FactsTexts,
} from "../Components/Texts";

export const ContactForm = props => {
	return (
		<div class="container text-center" style={{ border: "0px solid #ffffff" }}>
					<div
						style={{
							position: "relative",
							height: "1000px",
							overflow: "hidden",
							maxWidth: "100%",
							paddingBottom: "6px",
							marginBottom: "24px"
						}}
					>
						<iframe
							title="form"
							src="https://form.jotform.com/202055439123346"
							style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
							frameborder="0"
							allowfullscreen
						></iframe>
					</div>
				</div>
	)
};

export const Home = (props) => {
	const context = useContext(AppContext);
	//props.page === "home" ? context.lang.set("de") : context.lang.set("en");
	
	
	let sectionText = FactsTexts.find(obj => {
		return obj.section === props.page
	  })
	  
	return (
		<Fragment>
			<Navigation />
			{/* <Header /> */}
			<div class="text-center">
			<Image src={MainImage} style={{maxHeight:"230px", marginTop: "24px"}} fluid />
			</div>
			<section class="page-section mb-0">
			<div class="container text-center mb-5">
				{/* <div class="page-section-heading d-inline-block text-center mb-0"> */}
				{/* {JSON.stringify(sectionText.title_ENG, null, 2) } */}
				<h2 class="d-inline-block text-center mb-0">
					{String(sectionText && sectionText.title_ENG ? sectionText.title_ENG : "")}
				</h2>
				
				{/* </div> */}
			</div>
			<div class="divider-custom divider-dark mb-0">
				<div class="divider-custom-line"></div>
				<div class="divider-custom-icon">
					{/* <Icofont icon="icofont-tennis" /> */}
					<Image src={LogoSmall} style={{height:"30px"}} />
				</div>
				<div class="divider-custom-line"></div>
			</div>
			</section>
			<Container class="mb-5" style={{minHeight:"700px", verticalAlign: "text-top"}}>
				<Row>
					<Col ></Col>
					<Col sm={12} >
						<div style={{fontFamily: "Mulish", fontSize: "26px", margin: "0px 6px 24px 6px"}}>
							{sectionText && sectionText.title_ENG ? 
								String(sectionText.text_ENG).split("\n").map((i,key) => 
									{return <div key={key} style={{margin: "12px 6px 18px 6px"}}>{i}</div>;}
								) : ""}			
						</div>
						{props.page === "requestdemo" ? <ContactForm></ContactForm> : ""}
					</Col>
					<Col ></Col>
				</Row>
			</Container>
			
			
			
			{/* <section class="page-section bg-primary text-white mb-0" id="contact">
				<div class="container">
					<div class="text-center">
					
						
					</div>

					<div class="divider-custom divider-light mb-3">
						<div class="divider-custom-line"></div>
						<div class="divider-custom-icon">
							<Icofont icon="icofont-ui-call" />
						</div>
						<div class="divider-custom-line"></div>
					</div>

					<div class="row justify-content-center">
						<div class="col-lg-4">
							<div class="d-flex flex-column align-items-center">
								<div class="icon-contact mb-3">
									<i class="fas fa-mobile-alt"></i>
								</div>
								<div class="text-white">Phone</div>
								<div class="lead font-weight-bold">(044) 586 71 77</div>
							</div>
						</div>
						<div class="col-lg-4">
							<div class="d-flex flex-column align-items-center">
								<div class="icon-contact mb-3">
									<Icofont icon="icofont-home" />
								</div>
								<div class="text-white">Addresse</div>
								<div class="lead font-weight-bold text-center">
									CH-8832 <br></br>Wilen bei Wollerau <br></br>Schweiz
								</div>
							</div>
						</div>
					</div>
				</div>
			</section> */}
			{/* <OrderForm /> */}
			{/* <Footer /> */}
			<Copyrights />
		</Fragment>
	);
};

export default Home;
