

export const MenuOptions = [
	{
		string_ENG: "What is Bird?",
		ancor: "whatisbird",
	},
	{
		string_ENG: "Evolution of Bird",
		ancor: "evolutionofbird",
	},
	{
		string_ENG: "What Does Bird Do?",
		ancor: "whatdoesbirddo",
	},
	{
		string_ENG: "What Data Does Bird Capture?",
		ancor: "whatdatadoesbirdcapture",
	},
	{
		string_ENG: "Why Choose Bird?",
		ancor: "whychoosebird",
	},
	{
		string_ENG: "Implementation Time-Frame",
		ancor: "implementationtimeframe",
	},
	{
		string_ENG: "Request a Demo?",
		ancor: "requestdemo",
	},
];



export const FactsTexts = [
	{
		section: "home",
		image: "icofont-question",
		title_ENG: "A Reprise Consulting Product",
		text_ENG:
			" \n \n \n \n \n\n\n\n\n\n",
	},
	{
		section: "whatisbird",
		image: "icofont-question",
		title_ENG: "What is Bird?",
		text_ENG:
			"Bird is the first commercially-available, cloud-based, software solution specifically designed for the (re)insurance market, to reserve and manage large volumes of UK catastrophic bodily injury reserves. \nThe motor and liability sectors rely on quality and transparency of reserving data more than most. \n Bird provides carriers with a solution that fosters more standardised claims handling and reserving practices across your claims teams, whilst providing invaluable granular-level data to your underwriters and actuaries. \n Bird delivers the highest levels of claims data quality, driving a completely transparent reserving process.",
	},
	{
		section: "evolutionofbird",
		image: "icofont-clock-time",
		title_ENG: "Evolution of Bird",
		text_ENG:
			"Formed in 2014 by Graham Loughlin, a claims manager with over 33 years industry experience, Reprise Consulting Limited provides bespoke claims consultancy services to the London and International (re)insurance markets. " +
			"\n Following the first PPOs in 2008, as Head of UK Treaty Claims for a Global reinsurer, Graham led the design and implementation of a UK PPO module for an in-house-developed claims system. He has project-managed the implementation of claims and underwriting systems and, as a consultant, provided business analyst expertise for global claims transformation projects. Bird is the culmination of the past 12 years’ work. " +
			"\n The change in the UK Discount Rate in March 2017, and again, in July 2019, caused far-reaching challenges across the UK (re)insurance market.  Having been held at a steady 2.50% since 2001, the supply chain was ill-prepared to revalue the thousands of bodily injury claims that included a future loss element.  " +
			"\n Since its formation, the vast majority of Reprise’s consulting work has been focused on UK Bodily Injury Claims. During that time, Graham has reviewed over 2,000 cases for clients, providing technical claims reviews and reserve recommendations, whilst designing in-house reserving solutions for clients, which responded quickly to the 2017 and 2019 changes in Discount Rate. " +
			"\n This work identified a clear gap in the insurtech space, which Bird looks to fill. ",
	},
	{
		section: "whatdoesbirddo",
		image: "icofont-clock-time",
		title_ENG: "What Does Bird Do?",
		text_ENG:
			"BIRD captures vital bodily injury claims data, at individual claimant level, removing the reliance on multiple spreadsheets, which have become the de facto \"system\" in place for the vast majority of carriers. " +
			"\n Using mandatory data capture rules selected by you, Bird will automatically calculate your ultimate reserve on any given claim type (open cases, lump sum settlements or by any form of stepped, non-stepped and/or reverse indemnity PPO) at any discount rate, including split discount rates. " +
			"\n Within 24 hours of the July 2020 Ogden 8th Edition being published, Bird was updated to include eight new tables." +
			"\n In terms of data separation and clarity, cases involving multiple claimants have always proven particularly challenging for the market. The level of claims data captured in reinsurance renewal appendices (spreadsheets) has grown year-on-year, as the market tries to hit an ever-changing, ever-moving, target. " +
			"\n Bird is able to capture granular, per-claimant, information and automatically-calculate multiplier/multiplicand reserves for up to 99 claimants per event, for any combination of claim type above.  " +
			"\n Bird can be configured for use by an insurer or reinsurer. Using your data, Bird will attach reinsurance programme details to each claim and calculate paid, outstanding, incurred amounts per layer (participant share data for insurers, or line size share for reinsurers) and apply indexation. " +
			"\n For reinsurers, a cedant vs reinsurer reserve view will allow them to compare differing reserving methodologies across their client base. Bird will highlight differences, down to head of loss level and, if required, apply ACRs / IBNR shares to each layer. " +
			"\n Bird captures annual PPO instalments for the life of the claim, including revaluation of ultimate reserves in relation to reverse indemnity. " +
			"\n If you have messaging capability within your claims system, Bird can generate XML compatible output to automatically book / reconcile financials.",
	},
	{
		section: "whatdatadoesbirdcapture",
		image: "icofont-clock-time",
		title_ENG: "What Data Does Bird Capture?",
		text_ENG:
			"Bird can be configured to capture as much, or as little, data as you require. " +
			"\n Straight out of the box, Bird will capture and/or calculate:" +
			"\n 	- Insured and Loss Date" +
			"\n 	- Assigned Claims Handler" +
			"\n 	- Loss type, circumstances and narrative" +
			"\n 	- Line of Business (applying auto-capped limits for non-motor policies)" +
			"\n 	- Diary dates" +
			"\n 	- Reinsurance programme limits, retentions and participations (and apply indexation)" +
			"\n 	- Claimant Name, DOB, Gender and Profession" +
			"\n 	- Extent of Claimant Injuries (using industry standard codes)" +
			"\n 	- Reduction in life expectancy (all expert views and auto-calculate multipliers based on best, mid or worst case)" +
			"\n 	- Liability and/or Indemnity discounting factors" +
			"\n 	- Selectable care / medical expert and solicitor database" +
			"\n 	- Estimate propensity for settlement by way of PPO (based on your agreed scoring methodology)" +
			"\n 	- Past Losses, Generals and exhaustive Future Heads of loss options" +
			"\n 	- Instantly calculate life and FLOE multipliers (both gross and net) for any given Discount Rate" +
			"\n 	- Revalue reserves based on an anticipated future settlement date" +
			"\n 	- Automatic calculation of ultimate reserves" +
			"\n 	- Automated ultimate calculation for stepped, non-stepped and reverse indemnity PPO cases." +
			"\n 	- Automatic calculation of reinsurance programme recoveries / ACRs / IBNR for up to 99 claimants per-event",
	},
	{
		section: "whychoosebird",
		image: "icofont-clock-time",
		title_ENG: "Why Choose Bird?",
		text_ENG:
			"We all love spreadsheets. " +
			"\n You could continue to capture your, ever-growing, claims data in multiple spreadsheets, but they are…" +
			"\n 	- Not secure" +
			"\n 	- Memory-hungry" +
			"\n 	- Difficult to manage" +
			"\n 	- More prone to user-error" +
			"\n 	- Difficult to switch Discount Rate en masse" +
			"\n 	- Not generally supported by I.T., so if they crash, you’re on your own!" +
			
			"\n " +
			"\n So why not use a purpose-built solution that…" +
			"\n 	- Uses an intuitive, web-based interface, using Google design philosophy, which users will recognise" +
			"\n 	- Streamlines and standardises the management of complex UK Bodily Injury Claims" +
			"\n 	- Better-manages your reserves, based on sophisticated data analysis" +
			"\n 	- Easily splits out, then aggregates, detailed reserve calculations for multiple claimants" +
			"\n 	- Quickly and easily visualises your claims data via a live dashboard" +
			"\n 	- Is a future-proof solution that will respond instantly to a change in the Discount Rate " +
			"\n 	- Provides instant access to a suite of bespoke reports, with the ability to export to any format" +
			"\n 	- Has been developed using latest web technologies (.NET Core, React) to ensure high performance supported by all major web browsers, including iPads, Android tablets and smartphones" +
			"\n 	- Has fully integrated security inbuilt, supporting various types of user authentication, including integration with corporate directory services " +
			"\n 	- Has a high performance back-end designed using Microsoft's Azure solutions, developed with .NET Core framework, providing advantages such as :" +
			"\n 		- High performance, each client implementation can be configured according to individual requirements" +
			"\n 		- Cross-platform support" +
			"\n 		- Easier maintenance" +
			"\n 		- Fully encrypted database" +
			"\n 		- Cloud-based (Azure) deployments with infrastructure dedicated to each client" +
			"\n 		- Deployment in Azure region of your choice to comply with data protection regulations" +
			"\n 		- Built-in integration options with Azure Service Bus messaging and industry-standard REST APIs to allow for easy integration with underwriting, claims and other systems" +
			"\n 		- Easily configurable reporting dashboards",
	},
	{
		section: "implementationtimeframe",
		image: "icofont-clock-time",
		title_ENG: "Implementation Time-Frame",
		text_ENG:
			"BIRD can be implemented in a standalone / out-of-the-box format in a matter of days. " +
			"\nTo avoid manual data entry, we include flexible, secure and reliable integration options for any underwriting or claims management system that supports REST APIs or Azure messaging. " +
			"\nBIRD also allows for bulk data import/export of csv files as part of the standard functoinality. " +
			"\n More complex or specialised implementations that require design enhancements, functionality changes or custom integrations with existing data or systems will require further evaluation, so please contact us for an initial discussion.",
	},
	{
		section: "requestdemo",
		image: "icofont-clock-time",
		title_ENG: "Request a Demo?",
		text_ENG:
			"We are now inviting demos via Skype, MS Teams or Zoom." +
			"\n Please drop us a line at :",
	},

];



export default MenuOptions;
