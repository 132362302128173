import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";
import Home from "./Pages/Home";
import AppContext from "./Components/AppContext";

const App = () => {
	const [section, setSection] = useState("home");

	const store = {
		section: { get: section, set: setSection },
		//color: { get: color, set: setColor },
	};

	return (
		<AppContext.Provider value={store}>
			<div className="wrapper">
				<Router>
					<Switch>
						<Route exact path="/" render={(props) => <Home {...props} page={"home"} />} />
						<Route exact path="/whatisbird" render={(props) => <Home {...props} page={"whatisbird"} />} />
						<Route exact path="/evolutionofbird" render={(props) => <Home {...props} page={"evolutionofbird"} />} />
            <Route exact path="/whatdoesbirddo" render={(props) => <Home {...props} page={"whatdoesbirddo"} />} />
            <Route exact path="/whatdatadoesbirdcapture" render={(props) => <Home {...props} page={"whatdatadoesbirdcapture"} />} />
            <Route exact path="/whychoosebird" render={(props) => <Home {...props} page={"whychoosebird"} />} />
            <Route exact path="/implementationtimeframe" render={(props) => <Home {...props} page={"implementationtimeframe"} />} />
            <Route exact path="/requestdemo" render={(props) => <Home {...props} page={"requestdemo"} />} />
					</Switch>
				</Router>
			</div>
		</AppContext.Provider>
	);
};

export default App;

