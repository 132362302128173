import React, { useContext, Fragment } from "react";
//import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import NavbarBrand from "react-bootstrap/NavbarBrand";
import NavbarToggle from "react-bootstrap/NavbarToggle";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import "../Assets/Css/body.css";
import "../Assets/Css/heading.css";
import "../Assets/Css/styles.css";
import LogoSmall from "../Assets/Img/LogoSmall.png";
//import MainLogo from "../Assets/Img/Logo-stringing-180-white.png";
//import MainLogo from "../Assets/Img/MainLogo.jpg";
//import Favicon from "../Assets/Img/favicon.ico";

import { MenuOptions } from "./Texts";
import AppContext from "./AppContext";


const Navigation = () => {
	const context = useContext(AppContext);
	return (
		<Fragment>
			<div></div>
			<Navbar bg="light" collapseOnSelect expand="lg" variant="light" sticky="top">
				<Navbar.Brand href="/" style={{ width: "230px" }}>
					<img
						alt=""
						src={LogoSmall}
						width="30"
						height="30"
						class="float-left"
						style={{ margin: " 6px 12px 0px 6px" }}
					/>{" "}
					<h4 className="align-middle float-left" style={{ padding: "6px 12px 0px 0px" }}>
						Bird Claims
					</h4>
					
				</Navbar.Brand>

				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="ml-auto">
						{MenuOptions.map((item) => (
							<Nav.Link href={"/" + item.ancor}>
								<h5 style={{ padding: "24px 12px 12px 12px", align: "center" }}>
									{item.string_ENG}
								</h5>
							</Nav.Link>
						))}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
			
		</Fragment>

		// <Container className={"navbar navbar-expand-lg bg-secondary fixed-top"} id="mainNav">
	);
};

export default Navigation;
